var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "page bg-mid-black" }, [
    _c("header", { staticClass: "page-header bg-black" }, [
      _c("img", {
        attrs: { src: require("@/assets/spacein_logo_white.svg"), alt: "" },
        on: { click: _vm.routeToHome },
      }),
    ]),
    _c("section", { staticClass: "page-body" }, [
      _vm._m(0),
      _c("div", { staticClass: "body-content" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body-image" }, [
      _c("img", { attrs: { src: require("@/assets/login-image.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }