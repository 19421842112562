var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "space-invites" },
    [
      _c("login-pages-frame", [
        !_vm.loadingInviteData
          ? _c(
              "div",
              { staticClass: "space-invites__form padding-md bg-light-black" },
              [
                _vm.validInvite && _vm.ifAuthenticated
                  ? _c(
                      "div",
                      { staticClass: "space-invites__respond-to-invites" },
                      [
                        _vm.inviteStatus === "unused"
                          ? _c(
                              "h1",
                              { staticClass: "space-invites__space-name" },
                              [
                                _vm._v("You have been invited to "),
                                _c("br"),
                                _vm._v(" " + _vm._s(_vm.spaceName)),
                              ]
                            )
                          : _vm._e(),
                        _vm.inviteStatus === "unused"
                          ? _c(
                              "div",
                              { staticClass: "space-invites__respond" },
                              [
                                _c("btn", {
                                  staticClass: "space-invites__accept",
                                  attrs: {
                                    rounded: "",
                                    wide: "",
                                    large: "",
                                    customClasses: [
                                      "margin-auto",
                                      "text-white",
                                      "bg-green",
                                    ],
                                  },
                                  on: {
                                    onClick: () =>
                                      _vm.handleAcceptSpaceInvite([
                                        _vm.inviteToken,
                                        _vm.inviteData.spaceId,
                                      ]),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("b", [_vm._v("Accept Invite")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1653140764
                                  ),
                                }),
                                _c("btn", {
                                  staticClass: "space-invites__reject",
                                  attrs: {
                                    rounded: "",
                                    wide: "",
                                    large: "",
                                    customClasses: [
                                      "margin-auto",
                                      "bg-red",
                                      "text-white",
                                    ],
                                  },
                                  on: {
                                    onClick: () =>
                                      _vm.handleRejectSpaceInvite(
                                        _vm.inviteToken
                                      ),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("b", [_vm._v("Decline Invite")]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    80922448
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _c("div", [
                      _vm.inviteToken &&
                      !_vm.ifAuthenticated &&
                      _vm.ifCheckedAuth
                        ? _c(
                            "h1",
                            { staticClass: "space-invites__space-name" },
                            [
                              _vm._v("Please login or register to "),
                              _c("br"),
                              _vm._v(" respond to this invite"),
                            ]
                          )
                        : _vm.inviteStatus === "emailMismatch" && !_vm.isGuest
                        ? _c(
                            "div",
                            { staticClass: "space-invites__space-name" },
                            [
                              _c("p", [
                                _vm._v(
                                  "This invite was intended for someone else."
                                ),
                              ]),
                              _c("br"),
                              _c("p", [
                                _vm._v(
                                  "Please check your invite and register with the email that is used in the invitation."
                                ),
                              ]),
                            ]
                          )
                        : _vm.inviteStatus === "emailMismatch" && _vm.isGuest
                        ? _c(
                            "div",
                            { staticClass: "space-invites__space-name" },
                            [
                              _c("p", [
                                _vm._v("You are logged in as a guest."),
                              ]),
                              _c("br"),
                              _c("p", [
                                _vm._v(
                                  "Please register with the email that is used in the invitation to proceed."
                                ),
                              ]),
                            ]
                          )
                        : _vm.inviteStatus === "cancelled"
                        ? _c(
                            "div",
                            { staticClass: "space-invites__space-name" },
                            [
                              _c("p", [
                                _vm._v("This invite has been cancelled. "),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " Please request a new invite from the Space administrator."
                                ),
                              ]),
                            ]
                          )
                        : _vm.inviteStatus === "joined"
                        ? _c(
                            "div",
                            { staticClass: "space-invites__space-name" },
                            [
                              _c("p", [
                                _vm._v(
                                  "You have already accepted this invite!"
                                ),
                              ]),
                            ]
                          )
                        : _vm.inviteStatus === "rejected"
                        ? _c(
                            "div",
                            { staticClass: "space-invites__space-name" },
                            [
                              _c("p", [
                                _vm._v(
                                  "You have already declined this invite. "
                                ),
                                _c("br"),
                                _c("br"),
                                _vm._v(" If you have changed your mind, "),
                                _c("br"),
                                _vm._v(
                                  " you will need to request a new invite from the Space administrator."
                                ),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "space-invites__space-name" },
                            [
                              _c("p", [
                                _vm._v("This invite is no longer valid."),
                              ]),
                              _c("br"),
                              _c("br"),
                              _c("p", [
                                _vm._v(
                                  "You will need to request a new invite from the Space administrator."
                                ),
                              ]),
                            ]
                          ),
                      _c(
                        "div",
                        { staticClass: "space-invites__respond" },
                        [
                          _c("btn", {
                            staticClass: "space-invites__accept",
                            attrs: {
                              rounded: "",
                              wide: "",
                              large: "",
                              customClasses: [
                                "margin-auto",
                                "bg-green",
                                "text-white",
                              ],
                            },
                            on: { onClick: _vm.routeToLoginWithRedirect },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "text",
                                  fn: function () {
                                    return [
                                      !_vm.ifAuthenticated || _vm.isGuest
                                        ? _c("b", [_vm._v("Login or Register")])
                                        : _c("b", [_vm._v("Back To Spacein")]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1997739941
                            ),
                          }),
                          _vm.ifAuthenticated
                            ? _c("btn", {
                                staticClass: "space-invites__accept",
                                attrs: {
                                  rounded: "",
                                  wide: "",
                                  large: "",
                                  customClasses: [
                                    "margin-auto",
                                    "bg-red",
                                    "text-white",
                                  ],
                                },
                                on: { onClick: _vm.logout },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function () {
                                        return [
                                          _c("b", [
                                            _vm.isGuest
                                              ? _c("span", [
                                                  _vm._v(
                                                    "Logout of Guest Account"
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v("Switch Account"),
                                                ]),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1438535314
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }