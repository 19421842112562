var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page create-space" }, [
    _c("h2", { staticClass: "create-space__header" }, [
      _vm._v("Create new Space"),
    ]),
    _c(
      "section",
      { staticClass: "create-space__body" },
      [
        _c("TextInput", {
          staticClass: "create-space__input",
          attrs: { huge: "", placeholder: "Type a name of your Space" },
          model: {
            value: _vm.spaceName,
            callback: function ($$v) {
              _vm.spaceName = $$v
            },
            expression: "spaceName",
          },
        }),
        _c(
          "FooterButtons",
          { staticClass: "create-space__buttons" },
          [
            _c("btn", {
              attrs: { huge: "", colour: "ghost" },
              on: { onClick: _vm.cancelCreation },
              scopedSlots: _vm._u([
                {
                  key: "text",
                  fn: function () {
                    return [_vm._v(" Cancel ")]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("btn", {
              attrs: { huge: "", colour: "gold", disabled: _vm.isDisabled },
              on: { onClick: _vm.createArea },
              scopedSlots: _vm._u([
                {
                  key: "text",
                  fn: function () {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.creatingArea
                              ? "Creating Space..."
                              : "Create Space"
                          )
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }